  <template>
  <v-container fill-height>
    <v-row justify="center" align="center" v-if="!$store.state.currentUser">
      <v-col cols="12" class="text-center">
        <h1>欢迎报名参加清华大学天文系夏令营！</h1>
        <h2 v-if="deadline">
          本次报名截止时间为
          {{ toCNDate(deadline) }}
        </h2>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn class="ma-2" color="primary" to="/signup">注册/Sign up</v-btn>
        <v-btn class="ma-2" color="primary" to="/signin">登录/Sign in</v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" v-else>
      <v-col cols="6">
        <v-card v-if="$store.state.currentUser.role == 'none'">
          <v-card-title>选择您的角色/select your role</v-card-title>
          <v-card-text>
            <v-select
              v-model="role"
              :items="roleSelect"
              item-text="desc"
              item-value="name"
            ></v-select>
            <v-card-actions class="justify-center">
              <v-btn type="submit" :disabled="!role" color="primary" @click="handleChangeRole()">
                确认
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
        <v-card v-else-if="$store.state.currentUser.role == 'student'">
          <v-card-actions class="justify-center">
            <v-btn color="primary" to="/apply/detail">进入夏令营申请</v-btn></v-card-actions
          >
        </v-card>
        <v-card v-else-if="$store.state.currentUser.role == 'professor'">
          <v-card-actions class="justify-center">
            <v-btn color="primary" to="/review/grade">夏令营材料评分</v-btn></v-card-actions
          >
          <v-card-actions class="justify-center">
            <v-btn color="primary" to="/review/schedule">夏令营面谈设置</v-btn></v-card-actions
          >
        </v-card>
        <v-card v-else-if="$store.state.currentUser.role == 'admin'">
          <v-card-actions class="justify-center">
            <v-btn color="primary" to="/admin/users">管理用户</v-btn></v-card-actions
          >
          <v-card-actions class="justify-center">
            <v-btn color="primary" to="/admin/camp">管理夏令营资料</v-btn></v-card-actions
          >
          <v-card-actions class="justify-center">
            <v-btn color="primary" to="/admin/group">管理分组</v-btn></v-card-actions
          >
        </v-card>
        <v-card v-else>
          <v-card-text> under constructed</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import requests from "@/http";
import usersService from "@/services";

export default {
  data: () => ({
    role: "",
    deadline: null,
    roleSelect: [
      { name: "student", desc: "申请参加夏令营" },
      // { name: "applicant", desc: "申请博士后/教师职位" },
    ],
  }),
  created() {
    this.$store.commit("updateCurrentUser");
    this.getBasicInfo();
  },
  methods: {
    handleChangeRole() {
      requests.post(`/api/users/role`, { role: this.role }).then(() => {
        usersService.updateUserInfo().then(() => {
          this.$store.commit("updateCurrentUser");
          this.$router.go();
        });
      });
    },
    getBasicInfo() {
      requests.get(`/api/info`).then((res) => {
        this.deadline = new Date(res.data.deadline);
      });
    },
    toCNDate(date) {
      return `${date.getFullYear()}年${
        date.getMonth() + 1
      }月${date.getDate()}日 ${date.toLocaleTimeString("en-GB", { hour12: false })} CST`;
    },
  },
};
</script>
